<template>
  <div>
    <a-row :gutter="8">
      <a-col :span="4">
        <a-card>
          <a-statistic
            :value="38"
            :precision="0"
            :value-style="{ color: '#04c1c1' }">
            <template #title>
              巡检记录
              <a-tooltip placement="top">
                <template slot="title">
                  <span>指标说明</span>
                </template>
                <a-icon style="float: right" type="eye"/>
              </a-tooltip>
            </template>
            <template #prefix>
              <a-icon type="gateway"/>
            </template>
            <template #suffix>
              次
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card>
          <a-statistic
            :value="6"
            :precision="0"
            class="demo-class"
            :value-style="{ color: '#cf7b4f' }"
          >
            <template #title>
              巡检异常点
              <a-tooltip placement="top">
                <template slot="title">
                  <span>指标说明</span>
                </template>
                <a-icon style="float: right" type="eye"/>
              </a-tooltip>
            </template>
            <template #prefix>
              <a-icon type="alert"/>
            </template>
            <template #suffix>
              <span>个</span>
              <span class="statistic-item-suffix">已派单3个</span>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="当日行车任务"
            :value="60"
            :precision="0"
            class="demo-class"
            :value-style="{ color: '#1074e5' }"
          >
            <template #prefix>
              <a-icon type="car"/>
            </template>
            <template #suffix>
              <span>次</span>
              <span class="statistic-item-suffix">空闲8辆</span>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="故障上报"
            :value="10"
            :precision="0"
            suffix="个"
            class="demo-class"
            :value-style="{ color: '#f4b212' }"
          >
            <template #prefix>
              <a-icon type="phone"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="设备预警数"
            :value="600"
            :precision="0"
            suffix="个"
            class="demo-class"
            :value-style="{ color: '#cf1322' }"
          >
            <template #prefix>
              <a-icon type="bug"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="派发工单"
            :value="6"
            :precision="0"
            suffix="个"
            class="demo-class"
            :value-style="{ color: '#14d68b' }"
          >
            <template #prefix>
              <a-icon type="container"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name: 'home-top'
  }
</script>

<style>
  .statistic-item-suffix {
    color: #a8a8a8;
    margin-left: 30px;
  }
</style>